// Override Boostrap variables
@import "node_modules/bootstrap/scss/bootstrap";

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$red: #e23d28;
$green: #71bc78;

$blue: #6635f2;

$poap-blue-main: #6635f2;
$poap-blue-light: #bbc4ef;
$poap-blue-bg: #e9edf6;

$discord: #677ed5;

/* SCREEN SIZES */

// Smart phones (portrait view)
$screen-xs-min: 0px;
// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;
// Small tablets (portrait view)
$screen-md-min: 768px;
// Tablets and small desktops (includes Macbooks)
$screen-lg-min: 992px;
// Large tablets and desktops (bigger displays)
$screen-xl-min: 1300px;

// Xtrasmall devices
@mixin xs {
  @media (min-width: #{$screen-xs-min}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Xtralarge devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}
