@import "./badges/BadgesPage";
@import "./verify/Verify";
@import "./Header/Header";


.main-view-container {
  //max-height: calc(100vh) !important;
  //overflow: hidden !important;
}

.nav-wrapper {
  margin-bottom: 60px !important;
}

.crumbs-wrapper {
  margin-bottom: 40px !important;
  border-bottom: 1px solid black !important;
}

.crumbs-fixed {
  z-index: 1000;
  position: fixed;
  top: 60px;
  left: 0;
  background: $light !important;
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

.slight-shadow {
  box-shadow: 3px 5px 10px 3px $gray-200;
}
