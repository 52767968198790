.header-container {
    background: $blue;
    color: $white;
    min-height: 200px;
}

.header-logo {
    position: fixed;
    top: 20px;
    left: 30px;
}