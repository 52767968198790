.loading-overlay {
  display: inline-flex;
  color: theme-color("spinner-color");
  position: absolute;
  background: rgba(255,255,255,.5);
  text-align: center;
  font-size: 1em;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.small {
    font-size: 1em;
    /*width: 25%;*/
  }

  &.medium {
    font-size: 2em;
  }

  .spinner {
    margin: auto;
    align-self: center;
  }
}

.load-status {
  display: inline-block;
  text-align: center;
  height: 50px;
  color: theme-color("text-dark");
  z-index: 999999999;
  font-size: .8em;
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  margin-top: 0px;
  text-align: center;
  background: transparent;
}



.preloader {
    background-color: #ffffff;
    bottom: 0;
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999999;

    &.embedded {
        background: rgba(255,255,255,.5);
        display: inline-flex;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .title {
        display: inline-block;
        text-align: center;
        height: 50px;
        color: theme-color("init-text");
        font-size: 1.2em;
        position: absolute;
        top: 30%;
        right: 0;
        bottom: 0;
        left: 0;
        text-align: center;
    }





    span {
      background: rgb(72, 44, 191);
      background: -webkit-linear-gradient(45deg, rgba(72, 44, 191, 1) 0%, rgba(106, 198, 240, 1) 100%);
      background: -o-linear-gradient(45deg, rgba(72, 44, 191, 1) 0%, rgba(106, 198, 240, 1) 100%);
      background: linear-gradient(45deg, rgba(72, 44, 191, 1) 0%, rgba(106, 198, 240, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#482cbf', endColorstr='#6ac6f0', GradientType=1);
      border-radius: 100%;
      height: 60px;
      width: 60px;
      line-height: 60px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      text-align: center;
      color: #ffffff;
      font-size: 24px;
      margin: auto;
      -webkit-animation: mymove 1.5s infinite linear;
      animation: mymove 1.5s infinite linear;

      &.medium {
          height: 40px;
          width: 40px;
          line-height: 40px;
          font-size: 12px;
      }

      &.small {
        height: 10px;
        width: 10px;
        line-height: 10px;
        font-size: 2em;
      }
    }
}

@-webkit-keyframes mymove {
    0 {
        -webkit-box-shadow: 0 0 0 0 rgba(94, 136, 252, 0.6), 0 0 0 0 rgba(94, 136, 252, 0.6);
        box-shadow: 0 0 0 0 rgba(94, 136, 252, 0.6), 0 0 0 0 rgba(94, 136, 252, 0.6);
    }
    25% {
        -webkit-box-shadow: 0 0 0 10px rgba(94, 136, 252, 0.6), 0 0 0 10px rgba(94, 136, 252, 0.6);
        box-shadow: 0 0 0 10px rgba(94, 136, 252, 0.6), 0 0 0 10px rgba(94, 136, 252, 0.6);
    }
    50% {
        -webkit-box-shadow: 0 0 0 10px rgba(94, 136, 252, 0.6), 0 0 0 20px rgba(94, 136, 252, 0.6);
        box-shadow: 0 0 0 10px rgba(94, 136, 252, 0.6), 0 0 0 20px rgba(94, 136, 252, 0.6);
    }
    75% {
        -webkit-box-shadow: 0 0 0 10px rgba(94, 136, 252, 0.6), 0 0 0 10px rgba(94, 136, 252, 0.6);
        box-shadow: 0 0 0 10px rgba(94, 136, 252, 0.6), 0 0 0 10px rgba(94, 136, 252, 0.6);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(94, 136, 252, 0.6), 0 0 0 0 rgba(94, 136, 252, 0.6);
        box-shadow: 0 0 0 0 rgba(94, 136, 252, 0.6), 0 0 0 0 rgba(94, 136, 252, 0.6);
    }
}

@keyframes mymove {
    0 {
        -webkit-box-shadow: 0 0 0 0 rgba(94, 136, 252, 0.6), 0 0 0 0 rgba(94, 136, 252, 0.6);
        box-shadow: 0 0 0 0 rgba(94, 136, 252, 0.6), 0 0 0 0 rgba(94, 136, 252, 0.6);
    }
    25% {
        -webkit-box-shadow: 0 0 0 10px rgba(94, 136, 252, 0.6), 0 0 0 10px rgba(94, 136, 252, 0.6);
        box-shadow: 0 0 0 10px rgba(94, 136, 252, 0.6), 0 0 0 10px rgba(94, 136, 252, 0.6);
    }
    50% {
        -webkit-box-shadow: 0 0 0 10px rgba(94, 136, 252, 0.6), 0 0 0 20px rgba(94, 136, 252, 0.6);
        box-shadow: 0 0 0 10px rgba(94, 136, 252, 0.6), 0 0 0 20px rgba(94, 136, 252, 0.6);
    }
    75% {
        -webkit-box-shadow: 0 0 0 10px rgba(94, 136, 252, 0.6), 0 0 0 10px rgba(94, 136, 252, 0.6);
        box-shadow: 0 0 0 10px rgba(94, 136, 252, 0.6), 0 0 0 10px rgba(94, 136, 252, 0.6);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(94, 136, 252, 0.6), 0 0 0 0 rgba(94, 136, 252, 0.6);
        box-shadow: 0 0 0 0 rgba(94, 136, 252, 0.6), 0 0 0 0 rgba(94, 136, 252, 0.6);
    }
}
