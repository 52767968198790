.button-event-title {
  background-color: $poap-blue-bg;
  border-radius: 30px;
  color: $poap-blue-main !important;
  font-style: italic;
  font-weight: 400;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  border: none;
  cursor: pointer;

  @include xs() {
    padding: 0.3rem 1rem;
    font-size: 1.25rem;
  }
  @include md() {
    padding: 0.4rem 2.5rem;
    font-size: 1.6rem;
  }
  transition: all 0.3s;

  &:focus,
  &:active,
  &:hover {
    outline: none !important;
    background-color: $white !important;
    border: none;
  }

  &:hover {
    cursor: pointer;
  }
}
